import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import synergyLogo from './images/synergy.png';
import beraLogo from './images/berachain3.png';
import checkimg from './images/check.svg';
import metamaskLogo from './images/metamask.png';
import NetworkSelectFrom from './NetworkSelectFrom';
import NetworkSelectAssetEth from './NetworkSelectAssetEth';
import NetworkSelectAssetBsc from './NetworkSelectAssetBsc';

const ethers = require("ethers");
const { Option } = components;

const cuttingBoardContract = "0xfb81E39E3970076ab2693fA5C45A07Cc724C93c2";
const ETH_NODE_URL = 'https://bartio.rpc.berachain.com';

const abi = [
  "function queueNewCuttingBoard(address valCoinbase, uint64 startBlock, tuple(address receiver, uint96 percentageNumerator)[] weights)"
];


const SendForm = () => {

  let values = [];

  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (index, value) => {
    setInputValues(prev => ({ ...prev, [index]: { ...prev[index], amount: value } }));
  };


  const [perError, setPerError] = useState('');

  const [account, setAccount] = useState();
  const [ethBalace, setEthBalance] = useState(0);
  
  const [chainId, setChainId] = useState(1);
  const [disable, setDisable] = useState(false);
  const [sendTx, setSendTx] = useState(true);
  const [evmTx, setEvmTx] = useState('');
  const [blockNo, setBlockNo] = useState('');

  const [network, setNetwork] = useState('Ethereum');

  async function getLatestBlockNumber() {
    try {
        const response = await fetch(ETH_NODE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jsonrpc: '2.0',
                method: 'eth_blockNumber',
                params: [],
                id: 1
            })
        });

        const data = await response.json();

        // Parse the result to get the block number in decimal
        const blockNumberHex = data.result;
        const blockNumber = parseInt(blockNumberHex, 16);

        return blockNumber;
    } catch (error) {
        console.error("Error fetching latest block number:", error);
        return null; // Return null in case of an error
    }
  }

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        switchNetwork(80084);
      } catch (error) {
        console.error(error);
      }
    } else {
      // If MetaMask is not installed, alert the user
      alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    }
      let response = await fetch('https://beratools.synergynodes.com/vaults');
      values = await response.json();
      setData(values);
      const initialInputs = {};
      values.forEach((item, index) => {
        initialInputs[index] = { amount: '', contract: item.contract };
      });
      setInputValues(initialInputs);

  };  



  const switchNetwork = async (targetChainId) => {
    try {
      if (!window.ethereum) throw new Error("MetaMask is not installed!");

      setChainId(targetChainId);
  
      // Convert targetChainId to hex value
      const hexChainId = ethers.toQuantity(targetChainId);
  
      // Request the current chain ID
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
  
      if (currentChainId !== hexChainId) {
        try {
          // Attempt to switch to the target network
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: hexChainId }],
          });   

        } catch (switchError) {
          console.error('Switch network error:', switchError);
          // Handle other errors or provide guidance to the user
        }
      }
    } catch (error) {
      console.error('Failed to switch networks:', error);
      // Provide feedback to the user
    }
          // Request account access
          const newAccounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());          

        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setEthBalance(parseFloat(balanceInEth));
        setAccount(newAccounts[0]);
        setSendTx(false);

  };
  
  const estimateGasCost = async (provider, transaction) => {
    const estimatedGas = await provider.estimateGas(transaction);
    const gasPrice = (await provider.getFeeData()).gasPrice;
    return (estimatedGas * gasPrice); // Total gas cost in wei
  };
      

    const handleSubmit = async (e) => {
      e.preventDefault();

      const receivers = [];
      let totalPer = 0;

      Object.entries(inputValues).forEach(([index, { amount, contract }]) => {
        if (amount > 0) {
          
          totalPer = totalPer + parseFloat(amount);

          receivers.push({
            receiver: contract,
            percentageNumerator: parseFloat(amount) * 100
          });

          console.log(`Index: ${index}, Amount: ${amount}, Contract: ${contract}`);
        }
      });

      setPerError('');
      setEvmTx('');
      setSendTx(false);


      // let totalPer = parseFloat(perOne) + parseFloat(perTwo) + parseFloat(perThree) + parseFloat(perFour) + parseFloat(perFive) + parseFloat(perSix);

      console.log("totalPer = ", totalPer)

      if(totalPer != 100)
      {
        setPerError('Total Percentage must be 100%');
      }
      else
      {
         // Request account access
         const newAccounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());  
      
        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setAccount(newAccounts[0]);       

        setDisable('disabled');
        console.log("sendTx = ", sendTx);

        let bNumber = 0;        

        await getLatestBlockNumber().then(blockNumber => {
          if (blockNumber !== null) {
              console.log("Latest Block Number:", blockNumber);
              bNumber = blockNumber + 100;
          }
        }); 

        setBlockNo(bNumber);
    
          executeTx(cuttingBoardContract, account, bNumber, receivers)
            .then(() => console.log('Operation successful'))
            .catch((error) => console.error('Operation failed:', error));

      }
  
      // Implement the logic to handle form submission
    };


    async function executeTx(cuttingBoardContract, account, bNumber, receivers) {

      console.log("myTuples = ", receivers);
      
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      //var contract = new ethers.Contract(cuttingBoardContract, abi, provider);

      var contract = new ethers.Contract(cuttingBoardContract, abi, signer);

      

      //const gasEstimate = await contract.estimateGas.queueNewCuttingBoard(account, bNumber, receivers);
      //console.log('Gas Estimate:', gasEstimate.toString());

      const sendTxResponse = await contract.queueNewCuttingBoard(account, bNumber, receivers, {
          gasLimit: 1_000_000
      });


      //const sendTxResponse = await contract.queueNewCuttingBoard(account, bNumber, receivers);
      console.log('Send transaction submitted:', sendTxResponse);
      await sendTxResponse.wait();
      console.log("BSC Tx = ", sendTxResponse.hash);
      setSendTx(sendTxResponse.hash);
      setEvmTx('https://bartio.beratrail.io/tx/' + sendTxResponse.hash);
    }

  return (
    <div>  

    <div className="mainTop">
      <img src={beraLogo} /> Berachain Validator Tools from <img src={synergyLogo} /> <a href="https://www.synergynodes.com" target="_blank">Synergy Nodes</a>
      <p><b>Add / Update cutting board to the queue for Validator</b></p>
      <p><b>Supported Wallets:</b> <img src={metamaskLogo} /> Metamask</p>
    </div>


    <div className='mainBox'>
    
    <form onSubmit={handleSubmit}>

    { account ?
      <div className='oneBoxTitle'>
        <div className='oneLeftTitle'>
          Gauge Vaults <br />
        </div>
        <div className='oneRightTitle'>
          Percentage (%) <br />
        </div>
      </div> : ""
    }


    {data.map((item, index) => (
      <div key={index} className='oneBox'>
        <div className='oneLeftBottom'>
          <div className='divFrom'>
            <div className="gleft">
              <p className="top"><img src={`/images/${item.vault.toLowerCase()}.png`} alt={item.vault} onError={(e) => (e.target.src = '/images/default.png')} /> {item.vault}</p>
              <p className="bottom"><img src={`/images/${item.platform.toLowerCase()}.png`} alt={item.platform} onError={(e) => (e.target.src = '/images/default.png')} /> {item.platform}</p>
            </div>
            <div className='gright'>
              <p className="top">Incentives</p>
              <p className="bottom"><img src={`/images/${item.incentives.toLowerCase()}.png`} alt={item.incentives} onError={(e) => (e.target.src = '/images/default.png')} /> {item.incentives}</p>
            </div>
          </div>
        </div>
        <div className='oneRightBottom'>
        <div className='divTo'>
          <input
            className='inputClass'
            type="number"
            value={inputValues[index]?.amount}
            onChange={(e) => handleInputChange(index, e.target.value)}
            placeholder='Enter Percentage'
            disabled={!data}
          />
          <input
            type="hidden"
            value={item.contract}
          />
        </div>
      </div>  
      </div>
    ))}


        { account ?
        <div className="accDetails">
          <span>Wallet:</span>
          { account ? account : '' }
          <div className="balances">
            <p><span>BERA Balance:</span> { ethBalace ? ethBalace.toFixed(4) : '' }</p>
          </div>
        </div>
        : "" }

        { perError ? <div className='error'>Total Percentage must be 100%</div> : '' }

        { !account ?
          <button type="button" onClick={connectWalletHandler}>Connect Wallet</button>
          : 
          <button type="submit" disabled={disable}>{ disable && !sendTx ? <span className="loader2" /> : "" } { !sendTx ? 'Submit' : 'Done!' }</button>
        }  
        </form>  

        
        { !evmTx ? 
          <p className='myLink'><b>NOTE: </b> New settings will take effect in 100 Blocks from the current Block number.
          </p>
        : 
          <p className='myLink'><span className="tick-mark"><img src={checkimg} /></span> Berachain Tx - <a href={evmTx} target='_blank'>Link</a> 
          <br />
          <span className="tick-mark"><img src={checkimg} /></span> New settings will take effect from the Block Number: <b>{blockNo}</b>
          </p> 
        }


    </div>

    <p className='powered'>Powered by Berachain</p>

        <br />

    </div>


  );
};

export default SendForm;
